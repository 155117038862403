<template>
    <section class="pt-2">
        <tabs :tabs="rutas" class="border-bottom mb-3" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                { titulo: 'Clientes', ruta: 'admin.datos-cuentas.lanzamientos.clientes'},
                { titulo: 'Vendedores', ruta: 'admin.datos-cuentas.lanzamientos.leecheros'}
            ]
        }
    },
    mounted(){
        this.rutas[0].titulo = this.$config.cliente
        this.rutas[1].titulo = this.$config.vendedor
    }
}
</script>
